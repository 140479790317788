import React, { ErrorInfo } from 'react';


class ErrorHandler extends React.Component <object, { error: Error | null }> {
    constructor(props: object) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidCatch(error:Error, errorInfo:ErrorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.error) {
            return <div style={{ textAlign: 'center', position: 'fixed', height: '50%', width: '100%', bottom: 0, fontSize: '22px' }}>
                <span style={{fontWeight:'bold'}}>{'Error'}</span> <br/>
        Anything went wrong please reload page and try again
            </div>;
        }
        return this.props.children;
    }
}
export default ErrorHandler;
