import { all, put, takeLatest, select } from 'redux-saga/effects';
import {
    changePasswordRequest,
    changePasswordSuccess,
    checkAuth,
    checkAuthFailed,
    confirmSmsForgot,
    editUserRequest,
    editUserSuccess,
    forgotNextStep,
    logout,
    logoutSuccess,
    saveUser,
    sendSmsConfirmationForForgot,
    setAuthLoaderStatus,
    setChangePasswordError,
    setEditLoader,
    setAuthError,
    signInAction,
    signUpAction,
    signUpNextStep,
    sendSmsConfirmationForSignUp,
    confirmSmsSignUp,
    restorePasswordAction,
    changePhoneNumberError,
    changePhoneNumberSuccess,
    changePhoneNumberAction,
    confirmNewPhoneNumberAction,
    setMobileChangeVisible,
    uploadAvatarAction,
    setCheckUserStatus,
    removeAvatarAction,
    confirmationFailed,
    confirmationSuccess,
    checkConfirmationAction,
    checkUserForEmailAction,
    setEmailSuccess,
    setEmailAction,
    setCheckUserRequestSuccessStatus
} from '../../redux/action/auth';
import { ISignInAction } from '../../redux/interfaces/action';
import {AxiosResponse} from 'axios';
import service from '../../../service/service';
import {TRChangePhone, TResLogin} from '../../../Types/requests';
import {IUser, IWallet} from '../../../Types';
import {dropNftAction, setMailModalVisible} from '../../redux/action/nfts';



function* signInSaga({ payload }: { payload: ISignInAction }) {
    try {

        yield put(setAuthLoaderStatus(true));
        const { data } : AxiosResponse<TResLogin> = yield service.login(payload);
        if(data.user && !data.user?.roles.find(e => e === 'publisher'))  throw ['Credentials don\'t match.!'];
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refresh', data.refresh_token);
        yield put(saveUser(data.user));

    } catch (e: unknown) {
        yield put(setAuthLoaderStatus(false));
        if(Array.isArray(e)) yield put(setAuthError(e));
    }
}

function* checkUser () {
    try {
        yield put(setCheckUserStatus(true));
        const { data } : AxiosResponse<IUser> = yield service.getProfile();
        const { data: wallet } : AxiosResponse<IWallet> = yield service.getWallet();
        yield put(saveUser({ ...data, wallet }));
    }catch (e) {
        yield put(checkAuthFailed());
    }
}


function* logoutUser () {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    yield put(logoutSuccess());
}

function* sendConfirmationForgot ({ payload: phoneNumber }: { payload: string | { phoneNumber: string, withoutStep: boolean} }) {
    try {
        yield put(setAuthLoaderStatus(true));
        yield service.sendSmsConfirmationForgot(typeof phoneNumber === 'string' ? phoneNumber  : phoneNumber.phoneNumber);
        if(typeof  phoneNumber === 'string') yield put(forgotNextStep(  { phoneNumber }));
        else yield put(setAuthLoaderStatus(false));

    }catch (e) {
        yield put(setAuthLoaderStatus(false));
        if(Array.isArray(e)) yield put(setAuthError(e));
    }
}
function* confirmSmsForForgot ({ payload }) {
    try {
        yield put(setAuthLoaderStatus(true));
        const { data } : AxiosResponse<TResLogin> =yield service.confirmSms(payload.phoneNumber, payload.code);
        yield put(forgotNextStep({ phoneNumber: payload.phoneNumber, code: payload.code, token: data.access_token }));

    }catch (e) {
        yield put(setAuthLoaderStatus(false));
        if(Array.isArray(e)) yield put(setAuthError(e));
    }
}

function* sendConfirmationSignUp ({ payload: phoneNumber }: { payload: string | { phoneNumber: string, withoutStep: boolean} }) {
    try {
        yield put(setAuthLoaderStatus(true));
        /* const { data } : AxiosResponse<object> = */yield service.sendSmsConfirmationSignUp(typeof phoneNumber === 'string' ? phoneNumber  : phoneNumber.phoneNumber);
        if(typeof  phoneNumber === 'string') yield put(signUpNextStep(  { phoneNumber }));
        else yield put(setAuthLoaderStatus(false));

    }catch (e) {
        yield put(setAuthLoaderStatus(false));
        if(Array.isArray(e)) yield put(setAuthError(e));
    }
}
function* confirmSmsForSignUp ({ payload }) {
    try {
        yield put(setAuthLoaderStatus(true));
        yield service.confirmSms(payload.phoneNumber, payload.code);
        yield put(signUpNextStep({ phoneNumber: payload.phoneNumber, code: payload.code }));

    }catch (e) {
        yield put(setAuthLoaderStatus(false));
        if(Array.isArray(e)) yield put(setAuthError(e));
    }
}

function* editUser ({ payload }) {
    try {
        yield put(setEditLoader(true));
        const { data }: AxiosResponse<IUser> = yield service.editUser(payload);
        yield put(editUserSuccess(data));
    }catch (e) {
        yield put(setEditLoader(false));
    }
}

function* changePassword ({ payload }) {
    try {
        yield put(setEditLoader(true));
        yield service.changePassword(payload);
        yield put(changePasswordSuccess());

    }catch (e) {
        yield put(setEditLoader(false));
        if(Array.isArray(e)) yield put(setChangePasswordError(e));

    }
}

function* signUp ({ payload }) {
    try {
        yield put(setAuthLoaderStatus(true));
        const { data }: AxiosResponse<TResLogin> = yield service.signUp(payload);
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refresh', data.refresh_token);
        yield put(saveUser(data.user));
    }catch (e) {
        yield put(setAuthLoaderStatus(false));
        if(Array.isArray(e)) yield put(setAuthError(e));
    }
}

function* restorePassword ({ payload }: { payload: { password: string, token: string} }) {
    try {

        yield put(setAuthLoaderStatus(true));
        yield service.restorePassword(payload);
        window.location.href = '/login';
        yield put(setAuthLoaderStatus(false));

    }catch (e) {
        yield put(setAuthLoaderStatus(false));
        if(Array.isArray(e)) yield put(setAuthError(e));
    }
}

function* changePhoneNumber ({ payload }: { payload: TRChangePhone }) {
    try {
        yield put(setEditLoader(true));
        yield service.changePhoneNumber(payload.phoneNumber, payload.password);
        yield put(changePhoneNumberSuccess({ ...payload, step: 1 }));
    }catch (e) {
        if(Array.isArray(e)) yield put(changePhoneNumberError(e));
        yield put(setEditLoader(false));
    }
}

function* confirmNewPhoneNumber ({ payload }) {
    try {
        yield put(setEditLoader(true));
        const { data } : AxiosResponse<TResLogin> = yield service.confirmNewPhoneNumber(payload.phoneNumber, payload.code);
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refresh', data.refresh_token);
        yield put(saveUser(data.user));
        yield put(changePhoneNumberSuccess({ visible: false, step: 0 }));
        yield put(setMobileChangeVisible(false));
    }catch (e) {
        if(Array.isArray(e)) yield put(changePhoneNumberError(e));
        yield put(setEditLoader(false));
    }
}

function* uploadAvatar ({ payload }) {
    try {
        yield put(setEditLoader(true));
        const { user } = yield select(state => state.auth);
        const { data } : AxiosResponse<IUser> = yield service.uploadAvatar(payload);
        yield put(saveUser({ ...user, avatarFileName: data.avatarFileName }));
        yield put(setEditLoader(false));
    }catch (e) {
        yield put(setEditLoader(false));
    }
}

function* removeAvatar () {
    try {
        yield put(setEditLoader(true));
        const user: IUser = yield select(state => state.auth.user);
        yield service.removeAvatar();
        yield put(saveUser({ ...user, avatarFileName: null }));
        yield put(setEditLoader(false));
    }catch (e) {
        yield put(setEditLoader(false));
    }
}

function* checkConfirmation ({ payload }) {
    try {
        const { data }: AxiosResponse<{ email: string }> = yield service.checkEmailConfirmation(payload);
        yield put(confirmationSuccess(data.email));
    }catch (e) {
        yield put(confirmationFailed());
    }
}

function* checkUserForEmail ({ payload }) {
    try {
        yield put(setCheckUserRequestSuccessStatus(true));
        const { data }: AxiosResponse<IUser> = yield service.getProfile();
        if(data.email) {
            yield put(saveUser(data));
            yield put(dropNftAction(payload));
        }
        else yield put(setMailModalVisible(true));
        yield put(setCheckUserRequestSuccessStatus(false));
    }catch (e) {
        yield put(setCheckUserRequestSuccessStatus(false));
    }
}

function* setEmail ({ payload }) {
    try {
        yield put(setAuthLoaderStatus(true));
        yield service.setEmail(payload);
        yield put(setEmailSuccess(true));
        yield put(setAuthLoaderStatus(false));
    }catch (e) {
        if(Array.isArray(e)) yield put(setAuthError(e));
        yield put(setAuthLoaderStatus(false));
    }
}
export default function* billingSagas(): Generator<any> {
    yield all([
        yield takeLatest(setEmailAction, setEmail),
        yield takeLatest(checkUserForEmailAction, checkUserForEmail),
        yield takeLatest(checkConfirmationAction, checkConfirmation),
        yield takeLatest(removeAvatarAction, removeAvatar),
        yield takeLatest(uploadAvatarAction, uploadAvatar),
        yield takeLatest(confirmNewPhoneNumberAction, confirmNewPhoneNumber),
        yield takeLatest(changePhoneNumberAction, changePhoneNumber),
        yield takeLatest(restorePasswordAction, restorePassword),
        yield takeLatest(sendSmsConfirmationForSignUp, sendConfirmationSignUp),
        yield takeLatest(confirmSmsSignUp, confirmSmsForSignUp),
        yield takeLatest(signUpAction, signUp),
        yield takeLatest(changePasswordRequest, changePassword),
        yield takeLatest(editUserRequest, editUser),
        yield takeLatest(confirmSmsForgot, confirmSmsForForgot),
        yield takeLatest(sendSmsConfirmationForForgot, sendConfirmationForgot),
        yield takeLatest(checkAuth, checkUser),
        yield takeLatest(signInAction, signInSaga),
        yield takeLatest(logout, logoutUser)
    ]);
}
