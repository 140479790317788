import { createAction } from 'redux-act';
import {ISignInAction, TChangePhoneData, TForgotOrSignUpNext} from '../interfaces/action';
import {IUser} from '../../../Types';
import {TRChangePassword, TRChangePhone, TRSignUp} from '../../../Types/requests';

export const forgotNextStep = createAction<TForgotOrSignUpNext>('FORGOT_NEXT_STEP');
export const forgotPreviousStep = createAction('FORGOT_PREVIOUS_STEP');

export const signUpNextStep = createAction<TForgotOrSignUpNext>('SIGNUP_NEXT_STEP');
export const signUpPreviousStep = createAction('SIGNUP_PREVIOUS_STEP');

export const setMobileChangeVisible = createAction<boolean>('SET_MOBILE_CHANGE');

export const checkAuth = createAction('CHECK_AUTH');
export const checkAuthFailed = createAction('CHECK_AUTH_FAILED');
export const setAuthError = createAction<string[]>('SET_SIGN_IN_ERROR');

export const logout = createAction('LOGOUT');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');

export const setAuthLoaderStatus = createAction<boolean>('SET_AUTH_LOADER');


export const sendSmsConfirmationForSignUp = createAction<string | { phoneNumber: string, withoutStep: boolean }>('SEND_SMS_CONFIRMATION_SIGN_UP');
export const confirmSmsSignUp = createAction<TForgotOrSignUpNext>('CONFIRM_SMS_SIGN_UP');

export const sendSmsConfirmationForForgot = createAction<string | { phoneNumber: string, withoutStep: boolean }>('SEND_SMS_CONFIRMATION_FORGOT');
export const confirmSmsForgot = createAction<TForgotOrSignUpNext>('CONFIRM_SMS_FORGOT');
export const restorePasswordAction = createAction<{ password: string, token: string}>('RESTORE_PASSWORD');

export const changePhoneNumberAction = createAction<TRChangePhone>('CHANGE_PHONE_NUMBER');
export const confirmNewPhoneNumberAction = createAction<TForgotOrSignUpNext>('CONFIRM_NEW_PHONE_NUMBER');
export const changePhoneNumberError = createAction<null | string[]>('CHANGE_PHONE_NUMBER_ERRORS');
export const changePhoneNumberSuccess = createAction<TChangePhoneData>('CHANGE_PHONE_NUMBER_DATA');

export const setCheckUserStatus = createAction<boolean>('SET_CHECK_USER_STATUS');
export const uploadAvatarAction = createAction<FormData>('UPLOAD_AVATAR_SUCCESS');

export const removeAvatarAction = createAction('REMOVE_AVATAR');

export const editUserRequest = createAction<IUser>('EDIT_USER_REQUEST');
export const editUserSuccess = createAction<IUser>('EDIT_USER_SUCCESS');

export const changePasswordRequest = createAction<TRChangePassword>('CHANGE_PASSWORD');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const setChangePasswordError = createAction<string[]>('SET_CHANGE_PASSWORD_ERROR');

export const setEditLoader = createAction<boolean>('SET_EDIT_LOADER');

export const setEmailAction = createAction<string>('SET_EMAIL');
export const setEmailSuccess = createAction<boolean>('SET_EMAIL_SUCCESS');

export const checkUserForEmailAction = createAction<FormData>('CHECK_USER_EMAIL');
export const setCheckUserRequestSuccessStatus = createAction<boolean>('SET_CHECK_EMAIL_STATUS');

export const checkConfirmationAction = createAction<string>('CHECK_CONFIRMATION');
export const confirmationSuccess = createAction<string>('CONFIRMATION_SUCCESS');
export const confirmationFailed = createAction('CONFIRMATION_FAILED');

export const getUserAction = createAction('GET_USER_ACTION');
export const signInAction = createAction<ISignInAction>('SIGN_IN_ACTION');
export const signUpAction = createAction<TRSignUp>('SIGN_UP_ACTION');
export const saveUser = createAction<IUser>('SAVE_USERS');