import {createReducer} from 'redux-act';
import {INFTs} from '../interfaces/reducer';
import {
    createNftSuccess,
    dropNftError,
    dropNftSuccess, getBurnedNftNextPage, getBurnedNftSuccess,
    getCategoriesSuccess,
    getNextPageOfNFTsReportSuccess,
    getNftByIdSuccess,
    getNFTsNextPageSuccess,
    getNFTsReportSuccess,
    getNFTsSuccess,
    getTagsSuccess,
    resetCreateNftForm,
    setLoadingStatus, setMailModalVisible
} from '../action/nfts';

const initialState: INFTs = {
    isNFTsFetching: false,
    isNFTsLoadSuccessfully: false,
    categories: [],
    tags: [],
    NFTs: {
        items: [],
        meta: {
            totalPages: 0,
            totalItems: 0,
            currentPage: 0,
            itemCount: 0,
            itemsPerPage: 0
        }
    },
    NFT: null,
    nftNotFound: false,
    dropNftError: null,
    reports: {
        items: [],
        meta: {
            totalPages: 0,
            totalItems: 0,
            currentPage: 0,
            itemCount: 0,
            itemsPerPage: 0
        }
    },
    affiliatesNFTS: {
        items: [],
        meta: {
            totalPages: 0,
            totalItems: 0,
            currentPage: 0,
            itemCount: 0,
            itemsPerPage: 0
        }
    },
    emailModalVisible: false
};

export const nftsReducer = createReducer<INFTs>({}, initialState);

nftsReducer.on(setMailModalVisible, (state, payload) => ({ ...state, emailModalVisible: payload }));
nftsReducer.on(getBurnedNftSuccess, (state, payload) => ({ ...state, affiliatesNFTS: payload, isNFTsFetching: false }));
nftsReducer.on(getBurnedNftNextPage, (state, payload) => ({ ...state, isNFTsFetching: false, affiliatesNFTS: { ...payload, items: state.affiliatesNFTS.items.concat(payload.items) } }));
nftsReducer.on(getNFTsReportSuccess, (state, payload) => ({ ...state, isNFTsFetching: false, reports: payload }));
nftsReducer.on(getNextPageOfNFTsReportSuccess, (state, payload) => ({ ...state, isNFTsFetching: false, reports: { ...payload, items: state.reports.items.concat(payload.items) }}));
nftsReducer.on(dropNftError, (state, payload) => ({ ...state, dropNftError: payload }));
nftsReducer.on(getNftByIdSuccess, (state, payload) => ({ ...state, isNFTsFetching: false, nftNotFound: !payload, NFT: payload }));
nftsReducer.on(dropNftSuccess, state => {
    window.location.href = '/';
    return { ...state, isNFTsFetching: false };
});
nftsReducer.on(getNFTsNextPageSuccess, (state, payload) => ({ ...state, isNFTsFetching: false, NFTs: { ...payload, items: state.NFTs.items.concat(payload.items) }}));
nftsReducer.on(getNFTsSuccess, (state, payload) => ({ ...state, NFTs: payload, isNFTsFetching: false }));
nftsReducer.on(getTagsSuccess, (state, payload) => ({ ...state, tags: payload, isNFTsFetching: false }));
nftsReducer.on(resetCreateNftForm, state => ({ ...state, isNFTsLoadSuccessfully: false }));
nftsReducer.on(getCategoriesSuccess, (state, payload) => ({ ...state, categories: payload, isNFTsFetching: false }));
nftsReducer.on(createNftSuccess, (state) => ({ ...state, isNFTsFetching: false, isNFTsLoadSuccessfully: true }));
nftsReducer.on(setLoadingStatus, ((state, payload) => ({ ...state, isNFTsFetching: payload })));

export default nftsReducer;