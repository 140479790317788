import Api  from './api';
import {TRChangePassword, TRLogin, TRSignUp} from '../Types/requests';
import {IUser} from '../Types';
import {TSearchAffiliateParams, TSearchReportsParams} from '../store/redux/interfaces/action';

const service = {
    signUp: (data: TRSignUp) => {
        return Api.post('auth/signup', data, { params: { isWeb: true }});
    },
    login: (data: TRLogin) => {
        return Api.post('auth/login', data);
    },
    getProfile: () => {
        return Api.get('users/profile');
    },
    getWallet: () => {
        return Api.get('users/getWallet');
    },
    refreshToken: () => {
        return Api.post('auth/refresh', { refresh_token: localStorage.getItem('refresh') });
    },
    sendSmsConfirmationForgot: (phoneNumber: string) => {
        return Api.post('auth/usedPhoneNumberVerification', {phoneNumber});
    },
    sendSmsConfirmationSignUp: (phoneNumber: string) => {
        return Api.post('auth/phoneNumberVerification', {phoneNumber});
    },
    confirmSms: (phoneNumber: string, otpCode: string) => {
        return Api.post('auth/confirmCodeVerification', {phoneNumber, otpCode});
    },
    editUser: (data: IUser) => {
        return Api.patch('users/updateUserData', data);
    },
    changePassword: (data: TRChangePassword) => {
        return Api.patch('auth/changePassword', data);
    },
    restorePassword: ({token, password}: { password: string, token: string }) => {
        return Api.patch('users/updateUserData', {password}, {headers: {'Authorization': `Bearer ${token}`}});
    },
    changePhoneNumber: (newPhoneNumber: string, password: string) => {
        return Api.patch('users/changePhone', {newPhoneNumber, password});
    },
    confirmNewPhoneNumber: (phoneNumber: string, otpCode: string) => {
        return Api.post('auth/confirmNewPhone', {phoneNumber, otpCode});
    },
    uploadAvatar: (file: FormData) => {
        return Api.post('users/uploadAvatar', file);
    },
    removeAvatar: () => {
        return Api.delete('users/deleteAvatar');
    },
    checkEmailConfirmation: (token: string) => {
        return Api.get('users/verifyEmail', { params: { token } });
    },
    getAffiliateByPhone: (phoneNumber: string) => {
        return Api.get('users/getAffiliates', { params: { phoneNumber }});
    },
    addAffiliateByPhone: (phoneNumber: string) => {
        return Api.post('users/addAffiliate', { phoneNumber });
    },
    getMyAffiliates: (params?: TSearchAffiliateParams) => {
        return Api.get('users/getAffiliates', { params });
    },
    getCurrentAffiliateById: (id: string) => {
        return Api.get(`users/getAffiliate/${id}`);
    },
    blockAffiliate: (id: string) => {
        return Api.patch(`users/blockAffiliate/${id}`);
    },
    unblockAffiliate: (id: string) => {
        return Api.patch(`users/unblockAffiliate/${id}`);
    },
    setEmail: (email: string) => {
        return Api.patch('users/updateUserData', { email });
    },
    removeAffiliate: (id: string) => {
        return Api.delete(`users/deleteAffiliate/${id}`);
    },
    checkAffiliateExist: (phoneNumber: string) => {
        return Api.get('users/findAll', { params: { phoneNumber } });
    },
    createNft: (data: FormData) => {
        return Api.post('nft', data);
    },
    getCategories: () => {
        return Api.get('categories');
    },
    getTags: () => {
        return Api.get('nft-tags');
    },
    getCollections: (params) => {
        return Api.get('collections', { params });
    },
    getCollectionById: (id: string | number) => {
        return Api.get(`collections/${id}`);
    },
    exportNft: (id: string | number) => {
        return Api.get(`/nft/exportNFT/${id}`, { responseType: 'blob' });
    },
    dropNft: (data: FormData) => {
        return Api.post('transfers', data);
    },
    getNftReports: (params: TSearchReportsParams) => {
        return Api.get('transfers/reports', { params });
    },
    exportReportTable: (params: TSearchReportsParams) => {
        return Api.get('transfers/export', { params, responseType: 'blob' });
    },
    getBurnedNfts: (id: string, params: object) => {
        return Api.get(`nft/getBurnedNFTs/${id}`, { params });
    }
};
export default service;
