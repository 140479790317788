import {AxiosResponse} from 'axios';
import service from '../../../service/service';
import {all, put, select, takeLatest} from 'redux-saga/effects';
import {
    addAffiliateAction,
    addAffiliateError,
    addAffiliateSuccess,
    checkAffiliateAction,
    getAffiliateByIdAction,
    getAffiliatesAction, removeAffiliateAction,
    removeAffiliateFromTableSuccess,
    removeAffiliateSinglePageSuccess,
    saveAffiliate,
    saveCurrentAffiliate, saveFetchedWithPaginationResult,
    saveMyAffiliates,
    setBlockStatusOfAffiliateAction,
    setStatusOfAffiliateFetching
} from '../../redux/action/affiliates';
import {IUser} from '../../../Types';
import {TResAffiliates} from '../../../Types/requests';

function* checkAffiliate ({ payload }) {
    try {
        yield put(setStatusOfAffiliateFetching(true));
        const { data }: AxiosResponse<IUser[] | null> = yield service.checkAffiliateExist(payload);
        yield put(saveAffiliate(data[0] ? data[0] : { phoneNumber: payload }));
    }catch (e) {
        yield put(setStatusOfAffiliateFetching(false));
    }
}
function* addAffiliate ({ payload }) {
    try {
        yield put(setStatusOfAffiliateFetching(true));

        const { phoneNumber, isExist } = payload;
        const { affiliateFound } = yield select(state => state.affiliates);
        yield service.addAffiliateByPhone(phoneNumber);
        yield put(addAffiliateSuccess(isExist ? affiliateFound : null));

    }catch (e) {
        if(Array.isArray(e)) yield put(addAffiliateError(e));
        else yield put(setStatusOfAffiliateFetching(false));
    }
}
function* getMyAffiliates ({ payload }) {
    try {
        yield put(setStatusOfAffiliateFetching(true));
        const { data }: AxiosResponse<TResAffiliates> = yield service.getMyAffiliates(payload);
        if(payload.page) yield put(saveFetchedWithPaginationResult(data));
        else yield put(saveMyAffiliates(data));
    }catch (e) {
        yield put(setStatusOfAffiliateFetching(false));
    }
}
function* getAffiliateById ({ payload }) {
    try {
        yield put(setStatusOfAffiliateFetching(true));
        const { data }: AxiosResponse<IUser> = yield service.getCurrentAffiliateById(payload);
        yield put(saveCurrentAffiliate(data));

    }catch (e) {
        yield put(setStatusOfAffiliateFetching(false));
    }
}
function* setBlockStatusOfAffiliate ({ payload }) {
    try {
        yield put(setStatusOfAffiliateFetching(true));
        let updatedUser: IUser = {};
        if(payload.setBlock) updatedUser = (yield service.blockAffiliate(payload.id)).data;
        else updatedUser = (yield service.unblockAffiliate(payload.id)).data;
        yield put(saveCurrentAffiliate(updatedUser));
    }catch (e) {
        yield put(setStatusOfAffiliateFetching(false));
    }
}

function* removeAffiliate ({ payload }) {
    try {
        yield put(setStatusOfAffiliateFetching(true));
        yield service.removeAffiliate(payload.id);
        if(payload.isTable) yield put(removeAffiliateFromTableSuccess(payload.id));
        else yield put(removeAffiliateSinglePageSuccess());

    } catch (e) {
        yield put(setStatusOfAffiliateFetching(false));
    }
}

export default function* billingSagas(): Generator<any> {
    yield all([
        yield takeLatest(removeAffiliateAction, removeAffiliate),
        yield takeLatest(setBlockStatusOfAffiliateAction, setBlockStatusOfAffiliate),
        yield takeLatest(getAffiliatesAction, getMyAffiliates),
        yield takeLatest(getAffiliateByIdAction, getAffiliateById),
        yield takeLatest(addAffiliateAction, addAffiliate),
        yield takeLatest(checkAffiliateAction, checkAffiliate)
    ]);
}
