import {createAction} from 'redux-act';
import {ICollection, TCategories, TTags} from '../../../Types';
import {TSearchBurnedNFT, TSearchNFTsParams, TSearchReportsParams} from '../interfaces/action';
import {TResCollections, TResNFTs, TResReports} from '../../../Types/requests';

export const createNftAction = createAction<FormData>('CREATE_NFT_ACTION');
export const createNftSuccess = createAction('CREATE_NFT_SUCCESS');
export const resetCreateNftForm = createAction('REST_CREATE_NFT_FORM');

export const getCategoriesAction = createAction('GET_CATEGORIES_ACTION');
export const getCategoriesSuccess = createAction<TCategories[]>('GET_CATEGORIES_SUCCESS');

export const getTagsAction = createAction('GET_TAGS_ACTION');
export const getTagsSuccess = createAction<TTags[]>('GET_TAGS_SUCCESS');

export const getNFTsAction = createAction<TSearchNFTsParams>('GET_NFTS_ACTION');
export const getNFTsNextPageSuccess = createAction<TResCollections>('GET_NEXT_NFT_PAGE');
export const getNFTsSuccess = createAction<TResCollections>('GET_NFTS_SUCCESS');

export const getNftByIdAction = createAction<string>('GET_NFT_BY_ID_REQUEST');
export const getNftByIdSuccess = createAction<ICollection>('GET_NFT_BY_ID_SUCCESS');

export const dropNftAction = createAction<FormData>('DROP_NFT_ACTION');
export const dropNftSuccess = createAction('DROP_NFT_SUCCESS');
export const dropNftError = createAction<null | string[]>('DROP_NFT_ERROR');

export const getNFTsReportAction = createAction<TSearchReportsParams>('GET_NFTS_REPORT_ACTION');
export const getNFTsReportSuccess = createAction<TResReports>('GET_NFTS_REPORT_SUCCESS');
export const getNextPageOfNFTsReportSuccess = createAction<TResReports>('GET_NFTS_REPORT_NEXT_PAGE');

export const setMailModalVisible = createAction<boolean>('SET_MAIL_CONFIRAMTION_VISIBLE');

export const getBurnedNftAction = createAction<TSearchBurnedNFT>('GET_BURNED_NFT_ACTION');
export const getBurnedNftSuccess = createAction<TResNFTs>('GET_BURNED_SUCCESS');
export const getBurnedNftNextPage = createAction<TResNFTs>('GET_BURNED_NEXT_PAGE');

export const exportTableAction = createAction<TSearchReportsParams>('EXPORT_TABLE_ACTION');

export const exportNftAction = createAction<string | number>('EXPORT_NFT');

export const setLoadingStatus = createAction<boolean>('SET_FETCHING_NFT_STATUS');


