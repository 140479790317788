import { all } from 'redux-saga/effects';
import authSaga from './auth';
import affiliatesSaga from './affiliates';
import nftsSaga from './nfts';

export default function* rootSaga() {
    yield all([
        authSaga(),
        affiliatesSaga(),
        nftsSaga()
    ]);
}
