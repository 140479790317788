import React, {FC, memo, ReactNode} from 'react';
import Header from '../../Components/Header';

interface ILayoutProps {
    children?: ReactNode,
    isHeader?: boolean
}

const Layout: FC<ILayoutProps> = ({children, isHeader}) => {
    return (
        <>
            {
                isHeader ? <Header/> : null
            }

            <div className={'main'}>{children}</div>
        </>
    );
};


export default memo(Layout);
