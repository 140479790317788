import {
    AppBar,
    Box,
    Button,
    Container,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LogoutIcon from '@mui/icons-material/Login';
import React from 'react';
import style from './Components/header.module.css';
import { useLocation } from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from '../../store/redux/action/auth';
import {TRootState} from '../../store/store';
import {IAuthState} from '../../store/redux/interfaces/reducer';

const pages = [
    {
        title: 'Nfts',
        path: '/'
    },
    {
        title: 'Affiliate Persons',
        path: '/affiliate-persons'
    },
    {
        title: 'Reports',
        path: '/reports'
    },
    {
        title: 'Create',
        path: '/create'
    }];
const pagesMobile = pages.concat([
    {
        title: 'Profile',
        path: '/profile'
    }
]);


export default function Index () {

    const location = useLocation();
    const dispatch = useDispatch();

    const { user } = useSelector<TRootState, IAuthState>(state => state.auth);

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [anchorElWallet, setAnchorElWallet] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
    const handleOpenWalletMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElWallet(event.currentTarget);

    const handleCloseNavMenu = () => setAnchorElNav(null);
    const handleCloseUserMenu = () => setAnchorElUser(null);
    const handleCloseWalletMenu = () => setAnchorElWallet(null);

    const handleOnLogout = () => dispatch(logout());


    return <AppBar style={{ backgroundColor: '#FFFFFF', padding: '11px', boxShadow: ' 0px 4px 40px 0px #BABECC'}} className={style.container} position="static">
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, color: 'gray', display: { xs: 'none', md: 'flex' } }}
                >
                    <img src={'/logo.png'} width={62} height={62} />
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon fontSize={'large'}  style={{ color: '#B0AFBB'}}/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {pagesMobile.map((page) => (
                            <MenuItem key={page.title} href={page.path}>
                                <Typography textAlign="center">
                                    <a style={{ textDecoration: 'none', color: '#7F8393'}} href={page.path}>
                                        {page.title}
                                    </a>
                                </Typography>
                            </MenuItem>
                        ))}
                        <MenuItem onClick={handleOnLogout}>
                            <Typography  textAlign="center">{'Logout'}</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
                <Typography
                    variant="h6"
                    noWrap
                    className={style.label}
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                >
                    <img src={'/logo.png'} width={62} height={62} />
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                </Box>

                <Box sx={{ flexGrow: 0,  display: { xs: 'none', md: 'flex' } }}>

                    {pages.map((page) => (
                        <Button
                            style={{ marginRight: '20px'}}
                            key={page.path}
                            href={page.path}
                            onClick={handleCloseNavMenu}
                        >
                            <span className={style.label}>
                                {page.title}
                            </span>

                        </Button>


                    ))}
                    {/*<IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                    >
                        <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                            <NotificationsNoneOutlinedIcon fontSize={'large'} style={{ color: '#B0AFBB'}}/>
                        </Badge>
                    </IconButton>*/}
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenUserMenu}
                    >
                        <AccountCircleOutlinedIcon fontSize={'large'} style={{ color: '#B0AFBB'}}/>
                    </IconButton>
                    <Menu
                        sx={{ mt: '45px'}}
                        className={'custom-menu-styles'}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <Box className={style.userMenuContainer} sx={{ width: '276px'}}>
                            <Button onClick={() => window.location.href = '/profile'} className={`${style.buttons} ${location.pathname === '/profile' ? style.isProfile : ''}`} variant={'text'} fullWidth startIcon={<AccountCircleOutlinedIcon />}>
                                Profile
                            </Button>
                            <Divider className={style.divider}/>
                            <Button onClick={handleOnLogout} className={style.buttons} variant={'text'} fullWidth startIcon={<LogoutIcon />}>
                                Log out
                            </Button>
                        </Box>
                    </Menu>
                    <Menu
                        sx={{ mt: '45px'}}
                        className={'custom-menu-styles'}
                        id="menu-appbar-wallet"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElWallet)}
                        onClose={handleCloseWalletMenu}
                    >
                        <Box className={style.userMenuContainer} sx={{ width: '276px'}}>
                            <Button  className={`${style.buttons} ${style.isProfile}`} variant={'text'} fullWidth startIcon={<AccountBalanceWalletOutlinedIcon />}>
                                My wallet
                            </Button>
                            <div className={style.walletDescriptionBlock}>
                                {
                                    user && user.wallet && user.wallet.status === 'done'
                                        ?
                                        <span className={style.description}>{`Id ${user.wallet.address}`}</span>
                                        :
                                        <span className={style.description}>{'Creating...'}</span>
                                }

                                {/*<span className={style.total}>{'Total Balance'}</span>
                                <span className={style.moneyCount}>{`$ ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(FakePaymentData.count).replace('$', '')}`}</span>*/}
                            </div>
                        </Box>
                    </Menu>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenWalletMenu}

                    >
                        <AccountBalanceWalletOutlinedIcon fontSize={'large'} style={{ color: '#B0AFBB'}}/>
                    </IconButton>

                    {/*<Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>*/}
                </Box>
            </Toolbar>
        </Container>
    </AppBar>;
}