import {AxiosResponse} from 'axios';
import service from '../../../service/service';
import {all, put, takeLatest} from 'redux-saga/effects';
import {
    createNftAction,
    createNftSuccess,
    dropNftAction, dropNftError,
    dropNftSuccess,
    exportNftAction, exportTableAction, getBurnedNftAction, getBurnedNftNextPage, getBurnedNftSuccess,
    getCategoriesAction,
    getCategoriesSuccess, getNextPageOfNFTsReportSuccess, getNftByIdAction,
    getNftByIdSuccess,
    getNFTsAction,
    getNFTsNextPageSuccess, getNFTsReportAction, getNFTsReportSuccess,
    getNFTsSuccess,
    getTagsAction,
    getTagsSuccess,
    setLoadingStatus
} from '../../redux/action/nfts';
import {ICollection, TCategories, TTags} from '../../../Types';
import {TResCollections, TResNFTs, TResReports} from '../../../Types/requests';

function* createNft({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        yield service.createNft(payload);
        yield put(createNftSuccess());

    } catch (e) {
        yield put(setLoadingStatus(false));
    }
}

function* getCategories () {
    try {
        yield put(setLoadingStatus(true));
        const { data }: AxiosResponse<TCategories[]> = yield service.getCategories();
        yield put(getCategoriesSuccess(data));

    }catch (e) {
        yield put(setLoadingStatus(false));
    }
}

function* getTags () {
    try {
        yield put(setLoadingStatus(true));
        const { data }: AxiosResponse<TTags[]> = yield service.getTags();
        yield put(getTagsSuccess(data));

    }catch (e) {
        yield put(setLoadingStatus(false));
    }
}
function* getNFTs ({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data }: AxiosResponse<TResCollections> = yield service.getCollections(payload);
        if (payload.page) yield put(getNFTsNextPageSuccess(data));
        else yield put(getNFTsSuccess(data));
    } catch (e) {
        yield put(setLoadingStatus(false));
    }
}

function* exportNft ({ payload }) {
    try {
        const {data}: AxiosResponse<string> = yield service.exportNft(payload);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `nft-${payload}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }catch (e) {
        console.error(e);
    }
}
function* dropNft ({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        yield put(dropNftError(null));
        yield service.dropNft(payload);
        yield put(dropNftSuccess());
    }catch (e) {
        if(Array.isArray(e)) yield put(dropNftError(e));
        yield put(setLoadingStatus(false));
    }
}
function* getNftById ({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data }: AxiosResponse<ICollection> = yield service.getCollectionById(payload);
        yield put(getNftByIdSuccess(data));

    }catch (e) {
        if(Array.isArray(e) && e[0] === 'Collection not found!') yield put(getNftByIdSuccess(null));
        yield put(setLoadingStatus(false));
    }
}

function* getReports ({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } : AxiosResponse<TResReports> = yield service.getNftReports(payload);
        if(payload.page) yield put(getNextPageOfNFTsReportSuccess(data));
        else yield put(getNFTsReportSuccess(data));
    }catch (e) {
        yield put(setLoadingStatus(false));
    }
}
function* exportReportTable ({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data }: AxiosResponse<string> = yield service.exportReportTable({ ...payload, limit: 250 });
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'NFT_reports_export.xlsx');
        document.body.appendChild(link);
        link.click();
        yield put(setLoadingStatus(false));
    }catch (e) {
        yield put(setLoadingStatus(false));
    }
}
function* getBurnedNfts({ payload }) {
    try {
        yield put(setLoadingStatus(true));
        const { data } : AxiosResponse<TResNFTs> = yield service.getBurnedNfts(payload.affiliateId,payload.params);
        if(payload?.params?.page) yield put(getBurnedNftNextPage(data));
        else yield put(getBurnedNftSuccess(data));
    }catch (e) {
        yield put(setLoadingStatus(false));
    }
}
export default function* billingSagas(): Generator<any> {
    yield all([
        yield takeLatest(getBurnedNftAction, getBurnedNfts),
        yield takeLatest(exportTableAction, exportReportTable),
        yield takeLatest(getNFTsReportAction, getReports),
        yield takeLatest(getNftByIdAction, getNftById),
        yield takeLatest(dropNftAction, dropNft),
        yield takeLatest(exportNftAction, exportNft),
        yield takeLatest(getNFTsAction, getNFTs),
        yield takeLatest(getTagsAction, getTags),
        yield takeLatest(createNftAction, createNft),
        yield takeLatest(getCategoriesAction, getCategories)
    ]);
}
