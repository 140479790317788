import {createReducer} from 'redux-act';
import {IAffiliates} from '../interfaces/reducer';
import {
    addAffiliateError,
    addAffiliateSuccess,
    initStateAddAffiliateState, removeAffiliateFromTableSuccess, removeAffiliateSinglePageSuccess,
    saveAffiliate, saveCurrentAffiliate, saveFetchedWithPaginationResult, saveMyAffiliates,
    setStatusOfAffiliateFetching
} from '../action/affiliates';


const initialState: IAffiliates = {
    affiliateFound: null,
    isSearchFetching: false,
    notFound: false,
    successfullyAdded: false,
    myAffiliates: {
        meta: {
            currentPage: 0,
            itemsPerPage: 0,
            totalPages: 0,
            itemCount: 0,
            totalItems: 0
        },
        items: []
    },
    affiliate: {},
    addAffiliateError: null
};
export const affiliateReducer = createReducer<IAffiliates>({}, initialState);

affiliateReducer.on(saveFetchedWithPaginationResult, (state, payload) => ({
    ...state,
    isSearchFetching: false,
    myAffiliates: { ...state.myAffiliates, meta: payload.meta, items: state.myAffiliates.items.concat(payload.items) }
}));
affiliateReducer.on(removeAffiliateFromTableSuccess, ((state, payload) => ({
    ...state,
    isSearchFetching: false,
    myAffiliates: {
        ...state.myAffiliates,
        items: state.myAffiliates.items.filter(e => e.id != payload)
    }
})));
affiliateReducer.on(removeAffiliateSinglePageSuccess, (state) => {
    window.location.href = '/affiliate-persons';
    return { ...state, isSearchFetching: false, affiliate: { } };
});
affiliateReducer.on(addAffiliateError, (state, payload) => ({ ...state, addAffiliateError: payload, isSearchFetching: false }));
affiliateReducer.on(saveMyAffiliates, (state, payload) => ({ ...state, isSearchFetching: false, myAffiliates: payload }));
affiliateReducer.on(saveCurrentAffiliate, (state, payload) => ({ ...state, isSearchFetching: false, affiliate: payload }));
affiliateReducer.on(initStateAddAffiliateState, ((state) => ({ ...state, notFound: false, affiliateFound: null, successfullyAdded: false })));
affiliateReducer.on(saveAffiliate, ((state, payload) => ({...state, affiliateFound: payload, isSearchFetching: false, notFound: !payload.id })));
affiliateReducer.on(setStatusOfAffiliateFetching, ((state, payload) => ({ ...state, addAffiliateError: null, isSearchFetching: payload })));
affiliateReducer.on(addAffiliateSuccess, ((state, payload) => ({
    ...state,
    isSearchFetching: false,
    successfullyAdded: true,
    myAffiliates: payload ?
        {
            ...state.myAffiliates,
            items: state.myAffiliates.items.concat(payload)
        } : state.myAffiliates
})));

export default affiliateReducer;