import axios, {AxiosResponse} from 'axios';
import {TResLogin} from '../Types/requests';
const baseURL = process.env.REACT_APP_API_URL;

const Axios = axios.create({
    baseURL
});

Axios.interceptors.request.use( (req) => {
    return { ...req,
        headers: {
            'Accept': '*/*',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            ...req.headers
        }};
});

Axios.interceptors.response.use(
    (response) => Promise.resolve(response),
    async (error) => {
        const originalRequest = error.config;
        if(localStorage.getItem('refresh') && error.response.status === 401 && !error._retry) {
            originalRequest._retry = true;
            const test = axios.create({
                baseURL
            });
            const { data } : AxiosResponse<TResLogin> = await test.post('auth/refresh', {
                refresh_token: localStorage.getItem('refresh')
            });
            localStorage.setItem('token', data.access_token);
            localStorage.setItem('refresh', data.refresh_token);
            originalRequest.headers.Authorization = 'Bearer ' + data.access_token;
            return Axios(originalRequest);
        }
        const message = error.response.data && error.response.data.message ? error.response.data.message : error.response.data;
        return Promise.reject(Array.isArray(message) ? message : [message]);
    }
);

export default Axios;