import { createReducer } from 'redux-act';

import {
    changePasswordSuccess,
    checkAuthFailed,
    editUserSuccess,
    forgotNextStep,
    forgotPreviousStep,
    logoutSuccess,
    saveUser,
    setAuthLoaderStatus,
    setChangePasswordError,
    setEditLoader,
    setMobileChangeVisible,
    setAuthError,
    signUpNextStep,
    signUpPreviousStep,
    changePhoneNumberSuccess,
    changePhoneNumberError,
    setCheckUserStatus,
    confirmationSuccess,
    confirmationFailed, setEmailSuccess, setCheckUserRequestSuccessStatus
} from '../action/auth';
import { IAuthState } from '../interfaces/reducer';


const initialState: IAuthState = {
    user: {},
    forgot: {
        step: 0,
        data: {}
    },
    signUp: {
        step: 0,
        data: {}
    },
    isChangeEmailVisible: false,
    isAuthFetching: false,
    isEditFetching: false,
    authError: [],
    changePasswordError: null,
    changePhoneError: null,
    isChangePasswordSuccess: false,
    changePhoneNumber: {
        step: 0,
        visible: false,
        data: {}
    },
    isCheckUserFetching: false,
    confirmationFetching: true,
    confirmationSuccess: null,
    setEmailSuccess: false,
    newAvatar: null,
    isCheckEmailFetching: false
};


export const authReducer = createReducer<IAuthState>({}, initialState);

authReducer.on(setCheckUserRequestSuccessStatus, (state, payload) => ({ ...state, isCheckEmailFetching: payload }));
authReducer.on(setEmailSuccess, (state, payload) => ({ ...state, setEmailSuccess: payload, isAuthFetching: false }));
authReducer.on(confirmationFailed, state => ({ ...state, confirmationFetching: false, confirmationSuccess: null }));
authReducer.on(confirmationSuccess, (state, payload) =>({ ...state, confirmationSuccess: payload, confirmationFetching: false }));
authReducer.on(changePhoneNumberSuccess, (state, payload) => ({
    ...state,
    isEditFetching: false,
    changePhoneNumber: {
        ...state.changePhoneNumber,
        ...payload,
        data: {
            ...state.changePhoneNumber.data,
            ...payload
        }
    }
}));
authReducer.on(setCheckUserStatus, ((state, payload) => ({ ...state, isCheckUserFetching: payload })));
authReducer.on(changePhoneNumberError, (state, payload) => ({...state, changePhoneError: payload }));
authReducer.on(setChangePasswordError, (state, payload) => ({ ...state,isEditFetching: false, changePasswordError: payload }));
authReducer.on(changePasswordSuccess, state => ({ ...state, isEditFetching: false, isChangePasswordSuccess: true }));
authReducer.on(setEditLoader, (state, payload) => ({ ...state, isChangePasswordSuccess: false, changePasswordError: null, changePhoneError: payload ? null : state.changePhoneError, isEditFetching: payload}));
authReducer.on(editUserSuccess, (state, payload) => ({
    ...state,
    user: {
        ...state.user,
        ...payload
    },
    isEditFetching: false,
}));
authReducer.on(logoutSuccess, state => ({ ...state, user: {} }));
authReducer.on(setAuthError, (state, payload) => ({ ...state, authError: payload }));
authReducer.on(checkAuthFailed, ( state => {
    localStorage.removeItem('token');
    window.location.href = '/login';
    return { ...state, isCheckUserFetching: false };
}));
authReducer.on(setAuthLoaderStatus, ((state, payload) => ({...state, isAuthFetching: payload })));
authReducer.on(setMobileChangeVisible, (state, payload) => ({...state, isChangeEmailVisible: payload }));
authReducer.on(forgotNextStep, (state, payload) => ({
    ...state,
    isAuthFetching: false,
    authError: null,
    forgot: {
        ...state.forgot,
        step: state.forgot.step + 1,
        data: payload
    }
}));
authReducer.on(forgotPreviousStep, ((state) => {
    if(state.forgot.step === 0) {
        window.location.href = '/login';
        return {...state};
    }
    else return  {
        ...state,
        authError: null,
        forgot: {
            ...state.forgot,
            step: state.forgot.step - 1
        }
    };
}));
authReducer.on(signUpNextStep, (state, payload) => ({
    ...state,
    isAuthFetching: false,
    authError: null,
    signUp: {
        ...state.signUp,
        step: state.signUp.step + 1,
        data: { ...state.signUp.data, ...payload }
    }
}));
authReducer.on(signUpPreviousStep, ((state) => {
    if(state.signUp.step === 0) {
        window.location.href = '/login';
        return {...state};
    }
    else return  {
        ...state,
        authError: null,
        signUp: {
            ...state.signUp,
            step: state.signUp.step - 1
        }
    };
}));
authReducer.on(saveUser, (state, payload) => ({
    ...state,
    user: payload,
    isAuthFetching: false,
    isCheckUserFetching: false,
    isCheckEmailFetching: false
}));
export default authReducer;