import React, { FC } from 'react';
import Layout from '../../View/Layout';

const DefaultRoute: FC<{ component: FC, isHeader?: boolean }> = ({isHeader, component: Component }) => {
    return <Layout isHeader={Boolean(isHeader)}>
        <Component/>
    </Layout>;
};

export default DefaultRoute;
