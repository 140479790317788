import {createAction} from 'redux-act';
import {IUser} from '../../../Types';
import {TAddAffiliate, TRemoveAffiliate, TSearchAffiliateParams, TSetBlockStatus} from '../interfaces/action';
import {TResAffiliates} from '../../../Types/requests';

export const initStateAddAffiliateState = createAction('INIT_AFFILIATE_STATE');

export const getAffiliatesAction = createAction<TSearchAffiliateParams>('GET_AFFILIATES');
export const saveMyAffiliates = createAction<TResAffiliates>('SAVE_AFFILIATES');
export const saveFetchedWithPaginationResult = createAction<TResAffiliates>('SAVE_AFFILIATES_WITH_PAGINATION');

export const getAffiliateByIdAction = createAction<string>('GET_AFFILIATE_BY_ID');
export const saveCurrentAffiliate = createAction<IUser>('SAVE_MY_AFFILIATE');

export const addAffiliateAction = createAction<TAddAffiliate>('ADD_AFFILIATE');
export const addAffiliateSuccess = createAction<IUser | null>('ADD_AFFILIATE_SUCCESS');
export const addAffiliateError = createAction<string[]>('ADD_AFFILIATE_ERROR');

export const removeAffiliateAction = createAction<TRemoveAffiliate>('REMOVE_AFFILIATE');
export const removeAffiliateSinglePageSuccess = createAction('REMOVE_AFFILIATE_SUCCESS');
export const removeAffiliateFromTableSuccess = createAction<string>('REMOVE_AFFILIATES_FROM_TABLE_SUCCESS');

export const setBlockStatusOfAffiliateAction = createAction<TSetBlockStatus>('SET_BLOCK_STATUS_FOR_AFFILIATE');

export const checkAffiliateAction = createAction<string>('CHECK_AFFILIATE');
export const saveAffiliate = createAction<IUser | null>('SAVE_AFFILIATE');
export const setStatusOfAffiliateFetching = createAction<boolean>('SET_STATUS_OF_AFFILIATE');