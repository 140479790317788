import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './redux/reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(  sagaMiddleware));

export type TRootState = ReturnType<typeof store.getState>

sagaMiddleware.run(rootSaga);
export default store;