import React, {useEffect, FC} from 'react';
import {Navigate,} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as _ from 'lodash';
import {PagesPath} from '../interfaces';
import {checkAuth} from '../../store/redux/action/auth';
import Layout from '../../View/Layout';
import {TRootState} from '../../store/store';
import {IAuthState} from '../../store/redux/interfaces/reducer';


interface IProps {
  component: FC
}

const PrivateRoute = ({ component: Component }: IProps) => {

    const dispatch = useDispatch();
    const { user } = useSelector<TRootState, IAuthState>( state => state.auth);

    useEffect(() => {
        if (localStorage.getItem('token') && _.isEmpty(user)) {
            dispatch(checkAuth());
        }

    }, [dispatch, user]);

    const isLoggedIn = Boolean(localStorage.getItem('token'));

    return isLoggedIn || !_.isEmpty(user)
        ?
        <Layout isHeader>
            <Component/>
        </Layout>
        :
        <Navigate to={PagesPath.signin} replace/>;

};
export default PrivateRoute;