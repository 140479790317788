import React, {Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';
import PrivateRoute from './Routes/PrivateRoute';
import PublicRoute from './Routes/PublicRoute';
import {PagesPath} from './Routes/interfaces';
import ErrorBoundary from './Components/ErrorBoundary';
import {CircularProgress} from '@mui/material';
import DefaultRoute from './Routes/DefaultRoute';
import { StylesProvider } from '@mui/styles';

const SignInPage = React.lazy(() => import('./Pages/SignIn'));
const SignUpPage = React.lazy(() => import('./Pages/SignUp'));
const NFTsPage = React.lazy(() => import('./Pages/NFTs'));
const ForgotPassword = React.lazy(() => import('./Pages/ForgotPassword'));
const Profile = React.lazy(() => import('./Pages/Profile'));
const Affiliate = React.lazy( () => import('./Pages/Affiliate'));
const AffiliateSelected = React.lazy( () => import('./Pages/Affiliate/SelectedUser'));
const Reports = React.lazy(() => import('./Pages/Reports'));
const ConfirmationEmail = React.lazy(() => import('./Pages/Confirmation'));
const CreateNft = React.lazy( () => import('./Pages/Create'));
const DropNft = React.lazy(() => import('./Pages/NFT'));

const NotFound = () => {
    return (
        <div style={{ textAlign: 'center', position: 'fixed', height: '50%', width: '100%', bottom: 0, fontSize: '22px' }}><span style={{fontWeight:'bold'}}>{'404 '}</span>This page could not be found</div>
    );
};

const App = () => {
    return (  <StylesProvider injectFirst>
        <Suspense fallback={ <CircularProgress style={{
            width: '75px',
            height: '75px',
            left: 'calc(50% - 75px)',
            top: 'calc(50vh - 75px)',
            position: 'absolute'
        }} disableShrink />}>
            <ErrorBoundary>

                <Routes>
                    <Route path={PagesPath.homepage} element={<PrivateRoute component={NFTsPage}/>}/>
                    <Route path={PagesPath.signin} element={<PublicRoute component={SignInPage}/>}/>
                    <Route path={PagesPath.signup} element={<PublicRoute component={SignUpPage}/>}/>
                    {/*<Route path={PagesPath.forgot} element={<PublicRoute component={ForgotPassword}/>}/>*/}
                    <Route path={PagesPath.profile} element={<PrivateRoute component={Profile}/>}/>
                    <Route path={PagesPath.affiliate} element={<PrivateRoute component={Affiliate}/>}/>
                    <Route path={PagesPath.affiliateSelected} element={<PrivateRoute component={AffiliateSelected}/>}/>
                    <Route path={PagesPath.reports} element={<PrivateRoute component={Reports}/>}/>
                    <Route path={PagesPath.createNft} element={<PrivateRoute component={CreateNft}/>}/>
                    <Route path={PagesPath.dropNft} element={<PrivateRoute component={DropNft}/>}/>
                    <Route path={PagesPath.emailConfirmation} element={<DefaultRoute component={ConfirmationEmail} />}/>
                    <Route path={PagesPath.forgot} element={<DefaultRoute component={ForgotPassword} />}/>
                    {/*<Route path={PagesPath.emailConfirmation} element={<PrivateRoute component={ConfirmationEmail} />}/>*/}
                    <Route element={<DefaultRoute component={NotFound}/>}/>
                </Routes>

            </ErrorBoundary>
        </Suspense></StylesProvider>
    );
};

export default App;
