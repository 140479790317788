import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import {PagesPath} from '../interfaces';
import Layout from '../../View/Layout';
import {TRootState} from '../../store/store';
import {IAuthState} from '../../store/redux/interfaces/reducer';


const PublicRoute: FC<{ component: FC }> = ({ component: Component }) => {
    const {user} = useSelector<TRootState, IAuthState>(state => state.auth);

    const isLoggedIn = Boolean(localStorage.getItem('token'));

    return isLoggedIn || !_.isEmpty(user)
        ?
        <Navigate to={PagesPath.homepage} replace/>
        :
        <Layout>
            <Component/>
        </Layout>;
};

export default PublicRoute;
