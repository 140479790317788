export enum PagesPath {
  homepage = '/',
  signin = '/login',
  signup = '/signup',
  forgot = '/forgot',
  profile = '/profile',
  affiliate = '/affiliate-persons',
  affiliateSelected = '/affiliate-persons/:id',
  reports = '/reports',
  emailConfirmation = '/email/confirm/:token',
  createNft = '/create',
  dropNft = '/items/:id/drop'
}