import { combineReducers } from 'redux';
import authReducer from './auth';
import affiliates from './affiliates';
import nfts from './nfts';

export default combineReducers({
    auth: authReducer,
    affiliates,
    nfts
});
